// Override default variables before the import
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$body-bg: #fff;
$primary: #2FC4B6;
$secondary: #ABB5BE;

$buttons: (
    'primary': (
        'background-color': $primary,
        'text-color': white,
        'shadow-color': $primary,
    ),
    'secondary': (
        'background-color': #6C757D,
        'text-color': white,
        'shadow-color': #e3ebf7,
    ),
);

// Import Bootstrap and its default variables
@import '~mdb-react-ui-kit/dist/scss/mdb.core.scss';
@import '~mdb-react-ui-kit/dist/scss/mdb.free.scss';
@import '~mdb-react-ui-kit/dist/scss/mdb.pro.scss';
@import "@fortawesome/fontawesome-free/css/all.min.css";

body {
  font-family: 'Inter', cursive;
}