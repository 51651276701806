@import './custom/bootstrap.scss';

#root {
  height: 100vh;
  background-color: white;
  color: #282c34;
}

.hidden {
  display: none;
}

.main-navigation {
  .user-profile-image img {
    height: 30px;
  }
}

.player-container {
  background: $primary;

  .track-artwork-container {

    img, .image-placeholder {
      border-radius: 25px;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 25%, rgba(143,255,255,1) 25%);
      padding: 4%;
      margin: 10%;
      width: 80%;
    }

    .image-placeholder {
      height: 90%;
    }
  }

  .current-track-details {
    color: #454545;
    padding: 2% 0;

    h3 {
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 20px 0;
    }

    p {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.025rem;

      &.track-title {
        font-size: 3rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.06rem;
      }

      &.artist-name {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.03rem;
      }
    }

    @include media-breakpoint-up(xxl) {
      h3 {
        font-size: 2.75rem;
        min-height: 33%;
      }

      p {
        font-size: 2.25rem;

        &.track-title {
          font-size: 4rem;
        }

        &.artist-name {
          font-size: 2.5rem;
        }
      }
    }

  }

  .player-progress {
    .row {
      margin: 1.3rem 0;

      .left-counter {
        text-align: right;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: white;
      }

      .progress {
        margin: 0.5rem 0;
        height: 0.5rem;
      }

      .right-counter {
        text-align: left;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: white;
      }
    }
  }

  .player-controls {
    font-size: 3rem;

    & > div {
      padding: 0 2rem;

      i {
        padding: 0 1.2rem;

        &.play-pause-control {
          color: $primary;
          cursor: pointer;
        }

        &.positive-track-feedback,
        &.negative-track-feedback {
          color: white;
          cursor: pointer;
        }
      }
    }
  }

  .player-progress, .player-controls {
    height: 5.0625rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 56.79%), rgba(83, 100, 99, 0.80);
  }
}

.playlist-container {
  background: var(--Gray-200, #E9ECEF);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  background-image: url("../images/MediMusic-Banner-Young-Girl.jpeg");
  min-height: 1200px;

  .playlist-heading-container {
    .playlist-heading {
      align-self: stretch;
      margin: 0 0 1.1rem;
      color: #000;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.06rem;
    }

    .playlist-sub-heading {
      display: flex;
      margin: 0 0 1.4rem;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      color: #828282;

      /* Subheading */
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 2.25rem */
    }
  }

  .playlist-card {

    .card {
      background-color: transparent;
      box-shadow: none;

      &:hover {
        cursor: pointer;
      }
    }

    .card-body {
      height: 200px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: 0 auto;
    }

    .card-footer {
      width: 200px;
      margin: 0 auto;
    }
  }
}

.modal .modal-content {
  background-color: white;
  color: #282c34;
}

.form-control {
  background-color: white;
  color: #282c34;
}

.survey-modal {
  .progress {
    height: 8px;
  }
  .survey-subheading, .survey-pagination {
    color: var(--Secondary-color, #6C757D);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem; /* 162.5% */
  }
}

